import * as React from "react";
import Footer from "../components/Footer";
import {Container, Grid} from "@material-ui/core";
import { Link } from "gatsby";
import FixedNavBar from "../components/FixedNavbar";

const NotFoundPage = () => (
  <>
    <FixedNavBar />
    <section style={{marginTop:"60px"}}>
      <Container>
        <Grid container>
          <Grid item xs={12} className="textCenter">
            <h2>404 - Not Found</h2>
            <p className="bodyText">You're out of bounds</p>
            <Link to="/">
              <button className="primaryButton">
                Go to Homepage
              </button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </section>
    <Footer />
  </>
);

export default NotFoundPage;
